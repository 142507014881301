import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import store from '@/plugins/store';






export default {

  async post(url, accessToken, domain = false, data = {}) {

    //console.log(process.env.VUE_APP_API_BASE   );

    let headers = {
      'Content-Type': 'application/json',
    }

    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false)
      if (!accessToken) { return {} }
      headers["Authorization"] = `Bearer ${accessToken}`
    }

    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
    }

    if (process.env.VUE_APP_X_API_VERSION) {
      headers["X-API-Version"] = process.env.VUE_APP_X_API_VERSION;
    }


    // Default options are marked with *
    const response = await fetch(process.env.VUE_APP_API_BASE + url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers,
      redirect: 'follow', // manual, *follow, error
      //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response.json(); // parses JSON response into native JavaScript objects
  },


  async get(url, accessToken, domain = false) {

    console.log(["GET", url, accessToken, domain]);

    let headers = {
      'Content-Type': 'application/json',
    }

    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false)
      if (!accessToken) { return {} }
      headers["Authorization"] = `Bearer ${accessToken}`
    }



    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
    }

    if (process.env.VUE_APP_X_API_VERSION) {
      headers["X-API-Version"] = process.env.VUE_APP_X_API_VERSION;
    }

    try {


      // Default options are marked with *
      const response = await fetch(process.env.VUE_APP_API_BASE + url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers,
        redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return response.json(); // parses JSON response into native JavaScript objects

    }
    catch (e) {
      //console.log(e)
      return undefined;
    }
  },


  async getWithEtag(url, accessToken, domain = false, etag = null) {

    console.log(["GET (etag)", url, accessToken, domain, etag]);

    let headers = {
      'Content-Type': 'application/json',
    }

    if (etag) {
      headers['If-None-Match'] = etag
    }

    if (accessToken) {
      let accessToken = await firebase.auth()?.currentUser?.getIdToken(false)
      if (!accessToken) { return {} }
      headers["Authorization"] = `Bearer ${accessToken}`
    }



    if (domain) {
      headers["X-Domain"] = store.state.currentDomain;
    }

    if (process.env.VUE_APP_X_API_VERSION) {
      headers["X-API-Version"] = process.env.VUE_APP_X_API_VERSION;
    }


    try {


      // Default options are marked with *
      const response = await fetch(process.env.VUE_APP_API_BASE + url, {
        method: 'GET', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers,
        redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      if (response.status == 304) {
        return {
          status: 304
        }
      }

      console.log(response)

      response.headers.entries(x => {
        console.log([x])
      })

      let etag = response.headers.get("etag")

      console.log("Got an etag of: ", etag)

      return {
        etag: etag,
        status: response.status,
        data: await response.json(), // parses JSON response into native JavaScript objects
      }

    }
    catch (e) {
      //console.log(e)
      return undefined;
    }
  }

}
