<template>
  <nav
    ref="navMain"
    class="navbar"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <router-link class="navbar-item" :to="{ path: '/home' }">
        <img src="/img/senses.svg" width="112" height="28" />
      </router-link>

      <div v-if="isStaff" class="navbar-item">
        <div class="buttons">
          <router-link
            class="button"
            :class="newMessages ? 'is-dark pulsing' : 'is-outlined'"
            :to="{ path: '/school/message' }"
            >Messages</router-link
          >
        </div>
      </div>

      <a
        id="example-burger"
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="main-menu"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="main-menu" class="navbar-menu">
      <div class="navbar-start">
        <div v-if="isStaff" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Students </a>
          <div class="navbar-dropdown">
            <router-link
              v-if="isLevel25"
              class="navbar-item"
              :to="{ path: '/school/register/missing' }"
            >
              Missing
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ path: '/school/student/search/' }"
            >
              Search
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ path: '/school/timetable/staff' }"
            >
              All Staff Registers
            </router-link>
          </div>
        </div>

        <div
          v-if="runMode == 'testing'"
          class="navbar-item has-dropdown is-hoverable"
        >
          <a class="navbar-link"> Staff </a>
          <div class="navbar-dropdown">
            <router-link
              class="navbar-item"
              :to="{ path: '/school/student/search/' }"
            >
              Search
            </router-link>
            <router-link
              class="navbar-item"
              :to="{ path: '/school/timetable/staff' }"
            >
              All Staff Registers
            </router-link>
          </div>
        </div>

        <div v-if="isStaff" class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Manage </a>
          <div class="navbar-dropdown">
            <router-link
              v-if="requiresDepartment([47])"
              class="navbar-item"
              :to="{ path: '/school/lunch/manage' }"
            >
              Lunches
            </router-link>

            <router-link
              v-if="isATutor"
              class="navbar-item"
              :to="{ path: '/school/tutor/mine' }"
            >
              My Tutor
            </router-link>

            <router-link
              v-if="runMode == 'testing'"
              class="navbar-item"
              :to="{ path: '/school/student/upload' }"
            >
              Upload Data
            </router-link>

            <router-link
              v-if="isLevel25"
              class="navbar-item"
              :to="{ path: '/forms/' }"
            >
              Forms
            </router-link>

            <router-link
              v-if="isLevel25"
              class="navbar-item"
              :to="{ path: '/workflows/' }"
            >
              Workflows
            </router-link>

            <router-link
              v-if="isLevel25"
              class="navbar-item"
              :to="{ path: '/school/links/manage/' }"
            >
              Useful Links
            </router-link>

            <router-link
              v-if="isLevel25"
              class="navbar-item"
              :to="{ path: '/system/' }"
            >
              System
            </router-link>

            <router-link
              v-if="$store.getters.canPutStudentOnReport"
              class="navbar-item"
              :to="{
                path: '/school/timetable/cover/' + $store.state.currentISODate,
              }"
            >
              Cover
            </router-link>

            <router-link
              v-if="runMode == 'testing' && isLevel25"
              class="navbar-item"
              :to="{ path: '/school/groups/manage/' }"
            >
              Groups Manage
            </router-link>

            <router-link
              v-if="runMode == 'testing' && isLevel25"
              class="navbar-item"
              :to="{ path: '/school/files/manage/' }"
            >
              File Manager
            </router-link>
          </div>
        </div>

        <router-link
          v-if="isStaff"
          class="navbar-item"
          :to="{ path: '/school/links' }"
        >
          Useful Links
        </router-link>
      </div>

      <div class="navbar-end">
        <div
          class="navbar-item"
          v-if="runMode == 'testing' && user?._delegate?.accessToken"
        >
          <div class="buttons">
            <button
              class="button is-outlined is-dark"
              type="button"
              v-clipboard:copy="user._delegate.accessToken"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              Copy Token!
            </button>
          </div>
        </div>

        <div class="navbar-item" v-if="isStaff">
          <div class="buttons">
            <a
              class="button is-outlined is-dark"
              href="https://sense-services.canny.io/feature-requests"
              target="_blank"
              >Feedback</a
            >
          </div>
        </div>

        <div class="navbar-item">
          <div class="buttons" @click="showSystemModalFlag = true">
            <a class="button is-outlined is-dark">System</a>
          </div>
        </div>
        <div v-if="loggedIn" class="navbar-item">
          <div class="buttons" @click="logout">
            <a class="button is-outlined is-dark">Logout</a>
          </div>
        </div>
      </div>
    </div>
    <div class="modal" :class="{ 'is-active': showSystemModalFlag }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">System Information</p>
          <button
            class="delete"
            aria-label="close"
            @click="showSystemModalFlag = false"
          >
            >
          </button>
        </header>
        <section class="modal-card-body">
          <p><strong>Version:</strong> {{ version.version }}</p>
          <p><strong>Date:</strong> {{ version.date }}</p>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            @click="showSystemModalFlag = false"
          >
            Ok
          </button>
        </footer>
      </div>
    </div>
  </nav>
</template>

<script>
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import GitCommit from "../_git_commit";

//const ls = require( 'local-storage')

export default {
  name: "my-navbar",

  computed: {
    domainsAllowed: function () {
      return this.$store.getters.domainsAllowed.length > 0;
    },
    currentDomain: function () {
      return this.$store.state.currentDomain || "Choose Domain";
    },

    runMode: function () {
      return this.$getConst("runMode");
    },

    loggedIn: function () {
      return firebase.auth().currentUser !== null;
    },

    newMessages: function () {
      if (!this.$store.getters.newMessages) {
        return false;
      }

      return this.$router.history.current.fullPath != "/school/message";
    },

    version() {
      return GitCommit;
    },

    isLevel25: function () {
      return this.$store.getters.isAccessLevel(25);
    },

    isStaff() {
      return this.$store.getters.isStaff;
    },
  },

  methods: {
    onCopy: function (e) {
      alert("You just copied: " + e.text);
    },
    onError: function (e) {
      alert("Failed to copy text: " + e);
    },

    requiresDepartment: function (departments) {
      let intersection = departments.filter((x) =>
        this.$store.getters.currentUserDetails?.["DepartmentIds"]?.includes(x)
      );
      if (!intersection || intersection.length == 0) {
        return false;
      }
      return true;
    },

    isATutor: function () {
      return (
        Object.keys(this.$store.getters.currentUserDetails["tutorgroups"]) > 0
      );
    },

    logout: function () {
      firebase
        .auth()
        .signOut()
        .then(() => {
          localStorage.removeItem("ssUser");
          this.$router.replace("/login");
        });
    },

    changeDomain: function () {
      this.$router.replace("/school/chooser");
    },
  },
  data: function () {
    return {
      user: firebase.auth().currentUser,
      showSystemModalFlag: false,
    };
  },
  mounted: function () {
    var burger = document.getElementById("example-burger");
    var menu = document.getElementById("main-menu");
    burger.addEventListener("click", function () {
      burger.classList.toggle("is-active");
      menu.classList.toggle("is-active");
    });

    this.$refs.navMain.addEventListener(
      "click",
      function (e) {
        if (e.target.classList.contains("navbar-item")) {
          document.getElementById("main-menu").classList.remove("is-active");
        }
      },
      false
    );
  },
};
</script>

<style>
.pulsing {
  animation: pulser ease-in-out 1s 2;
  animation-iteration-count: infinite;
}

@keyframes pulser {
  0% {
    background-color: #00d1b2;
    color: #000;
  }

  100% {
    background-color: #fff;
    color: #000;
  }
}

.flashing {
  animation: blinking ease-in-out 1s 2;
  animation-iteration-count: infinite;
}

@keyframes blinking {
  0% {
    color: #485fc7;
  }

  100% {
    color: red;
  }
}
</style>
