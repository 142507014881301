const SITE_CONSTANTS = {
   FORMS_HUMAN_CHECK: -1,
   FORMS_SUBMIT: -99,
   FORMS_SUBMITTED: -50,
   FORMS_FIRST_SECTION: 0,

  runMode: process.env.VUE_APP_STAGE ?? "production"
}

let SiteConstants = {}; // As suggested by the comments.

SiteConstants.install = function (Vue, _options) {
  Vue.prototype.$getConst = (key) => {
    console.log("CONSTANT", key, SITE_CONSTANTS[key])
    return SITE_CONSTANTS[key]
  }
}

export default SiteConstants