import Vue from 'vue'

import 'core-js'; // only stable feature also is possible with only `core-js`
import 'regenerator-runtime/runtime';


import VueCookies from 'vue-cookies'

import App from './App.vue'
import Vuex from 'vuex'
import router from './plugins/router'
//import './registerServiceWorker'
import wb from "./registerServiceWorker";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import store from './plugins/store';
import SiteConstants from './plugins/global'
import 'helpers/icons'
import VTooltip from 'v-tooltip'
import VueFormJsonSchema from 'vue-form-json-schema';


import api from 'helpers/apicalls.js'
//import  VoerroTagsInput from  '@voerro/vue-tagsinput';

import "vue-easytable/libs/theme-default/index.css";
import VueEasytable from 'vue-easytable'
import VuePhoneNumberInput from 'vue-phone-number-input';







Vue.prototype.$workbox = wb;


//console.log([process.env.VUE_APP_API_BASE]   );

Vue.config.productionTip = false

Vue.filter('pluralize', (amount, singular, plural = `${singular}s`) => amount === 1 ? singular : plural);

Vue.use(VueCookies, { expires: '7d'})
Vue.use(Vuex)
Vue.use(VTooltip)
Vue.use(VueEasytable)
Vue.use(SiteConstants)



Vue.component('vue-phone-number-input', VuePhoneNumberInput);


Vue.component('vue-form-json-schema', VueFormJsonSchema);
//Vue.component('tags-input', VoerroTagsInput);

let app = '';



// Initialize Firebase
firebase.initializeApp({
  apiKey: "AIzaSyAjC1AM41PPUAOgAbbC-tTcMW1C-4O8cKk",
  //authDomain: "auth.sense.services",

  authDomain: "auth.sense.services",
  projectId: "lunch-2e735",
  storageBucket: "lunch-2e735.appspot.com",
  messagingSenderId: "393265244869",
  appId: "1:393265244869:web:307685e3f4d6b44dd61eac"
});


import 'bulma-switch/dist/css/bulma-switch.min.css';
//import '@/assets/main.scss';
import "../node_modules/bulma/css/bulma.css";
import '@voerro/vue-tagsinput/dist/style.css';

import 'vue-phone-number-input/dist/vue-phone-number-input.css';

import './registerServiceWorker'


import './master.css.js';

Vue.prototype.$api = api;

firebase.auth().onAuthStateChanged((x) => {

  if (x) {
    const user = {}
    Object.assign(user, x._delegate)
    store.commit('currentUser', user)
  }
  else {
    store.commit('currentUser', null);
  }

  if (!app) {
    app = new Vue({
      router,
      store,
      VTooltip,
      render: h => h(App)
    }).$mount('#app');
  }
});
