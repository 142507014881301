import { init, Style } from '@master/css'




Style.extend('classes', {
    'title2': 'font:2em  {font-variant:small-caps}'
})


init()