<template>
    <div class="modal" :class="showModalFlag ? 'is-active' : '' ">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ title }}</p>
          <button class="delete" aria-label="close" v-on:click="cancelModal()">></button>
        </header>
        <section class="modal-card-body">
          <p v-for="(line,n) in message.split('\n')" :key="n+line" class="mb-1">{{ line }}</p>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-success" v-on:click="okModal()">Ok</button>
          <button class="button" v-on:click="cancelModal()">Cancel</button>
        </footer>
      </div>
    </div>
</template>


<script>


export default {
  name: "modal-modal",

  data: function () {
    return {
        showModalFlag: false,
        okPressed: false,
        message: "",
        title: "",
        referenceData: null,
      };
    },
  methods: {
    showModal: function(message, title, referenceData=null) {
      this.okPressed = false;
      this.showModalFlag = true;
      this.message = message
      this.title = title
      this.referenceData = referenceData
    },
    okModal: function() {
      this.okPressed = true;
      this.$emit("okPressed", this.referenceData)
      this.showModalFlag = false;
    },
    cancelModal: function() {
      this.okPressed = false;
      this.$emit("cancelPressed")
      this.showModalFlag = false;
    }
  }
}
</script>