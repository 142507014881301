import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

const ls = require('local-storage')

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [

    {
      path: '/',
      redirect: '/home'
    },
    {
      path: '/login',
      redirect: '/auth'
    },

    {
      path: '/auth',
      name: 'auth',
      component: () => import("@/views/Auth.vue")
    },

    {
      path: '/verify',
      name: 'verify',
      component: () => import("@/views/EmailVerification.vue"),
      meta: {
        allowedUnverifiedEmail: true,
        requiresAuth: true,
      }


    },



    {
      path: '/interstitial',
      name: 'interstitial',
      component: () => import("@/views/Interstitial.vue"),
      meta: {
        requiresAuth: true,
      }


    },


    {
      path: '/form',
      redirect: "/",
    },

    {
      path: '/workflows',
      name: 'workflows',
      component: () => import('@/views/Workflows/Manage.vue'),
      meta: {
        requiresStaff: true,
      },
      children: [
        {
          name: "workflowsManageCreate",
          path: 'create/',
          component: () => import("@/views/Workflows/Create.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          }
        },
        /*       {
                 name: "workflowsManageFormOverview",
                 path: 'id/:workflowID/',
                 component: () => import("@/views/Workflows/Overview.vue"),
                 meta: {
                   requiresAuth: true,
                   requiresDomain: true,
                 }
               },
               {
                 name: "workflowsManageFormSubmission",
                 path: 'id/:workflowID/submission/:subDate/:submissionID',
                 component: () => import("@/views/Workflows/Review.vue"),
                 meta: {
                   requiresAuth: true,
                   requiresDomain: true,
                 }
               },
               {
                 name: "workflowsManageFormSubmissionPrint",
                 path: 'id/:workflowID/submission/:subDate/:submissionID/:action',
                 component: () => import("@/views/Workflows/Review.vue"),
                 meta: {
                   requiresAuth: true,
                   requiresDomain: true,
                   hidenav: true,
                 }
               },
               {
                 name: "workflowsManageFormEdit",
                 path: 'id/:workflowID/edit/',
                 component: () => import("@/views/Workflows/Edit.vue"),
                 meta: {
                   requiresAuth: true,
                   requiresDomain: true,
                 }
               },*/
      ]

    },

    {
      path: '/forms',
      name: 'forms',
      component: () => import('@/views/Forms/Manage.vue'),
      meta: {
        requiresStaff: true,
      },
      children: [
        {
          name: "formsManageCreate",
          path: 'create/',
          component: () => import("@/views/Forms/Create.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          }
        },
        {
          name: "formsManageFormOverview",
          path: 'id/:formID/',
          component: () => import("@/views/Forms/Overview.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          }
        },
        {
          name: "formsManageFormSubmission",
          path: 'id/:formID/submission/:subDate/:submissionID',
          component: () => import("@/views/Forms/Review.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          }
        },
        {
          name: "formsManageFormSubmissionPrint",
          path: 'id/:formID/submission/:subDate/:submissionID/:action',
          component: () => import("@/views/Forms/Review.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
            hidenav: true,
          }
        },
        {
          name: "formsManageFormEdit",
          path: 'id/:formID/edit/',
          component: () => import("@/views/Forms/Edit.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          }
        },
      ]

    },

    {
      path: '/form/:formID',
      name: 'form',
      component: () => import('@/views/Form.vue'),
      meta: {
        hidenav: true,
      }
    },

    {
      path: '/visitor',
      name: "visitor",
      component: () => import('@/views/Visitors/Visitor.vue'),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      }

    },
    {
      path: '/kiosk-checkin-visitor',
      name: "kiosk-checkin-visitor",
      component: () => import("@/views/Checkins/Visitor.vue"),
      meta: {
        hidenav: true,
        kiosk: true,
        requiresDomain: true,
      }
    },
    {
      path: '/kiosk',
      name: "kiosk",
      component: () => import("@/views/VisitorCheckin.vue"),
      meta: {
        hidenav: true,
        kiosk: true,
      },

    },

    {
      path: '/school/student/upload',
      name: 'upload',
      component: () => import("@/views/Upload.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      }
    },

    {
      path: '/school/chooser',
      name: "schoolChooser",
      component: () => import("@/views/School/Chooser.vue"),
      meta: {
        requiresAuth: true,
        requiresDomains: true,
      }

    },
    {
      path: '/home',
      name: "schoolHome",
      component: () => import("@/views/School/Home.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      }

    },
    {
      path: '/school/lunch',
      name: "schoolLunch",
      component: () => import("@/views/School/Lunch.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      }

    },
    {
      path: '/school/lunch/manage',
      name: "schoolLunchManager",
      component: () => import("@/views/School/LunchManager.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresDepartment: [47], // TODO - store this in the school DO
      }

    },
    {
      path: '/school/timetable',
      component: () => import("@/views/School/Timetable.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
      },
      redirect: "/school/timetable/mine/",

      children: [

        {
          name: "schoolTimetableMine",
          path: 'mine/',


          component: () => import("@/views/School/Timetable/Mine.vue"),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
          }
        },



        {
          path: 'group/:gid+',
          name: "schoolTimetableGroupBase",
          component: () => import('@/views/School/Timetable/Group.vue'),

          redirect: { name: "schoolTimetableGroupBase.schoolTimetableGroup" },
          children: [
            {
              path: 'cards',
              name: "schoolTimetableGroupBase.schoolTimetableGroup",
              component: () => import('@/views/School/Timetable/Group/Group.vue'),
              meta: {
                requiresAuth: true,
                requiresDomain: true,
                requiresStaff: true,
              },
            },

            {
              name: "schoolTimetableGroupAttributes",
              path: 'attr/',

              props: true,
              component: () => import("@/views/School/Timetable/Group/GroupAttributes.vue"),
              meta: {
                requiresAuth: true,
                requiresDomain: true,
              }
            },
            {
              name: "schoolTimetableGroupDayBooks",
              path: 'daybooks/',

              props: true,
              component: () => import("@/views/School/Timetable/Group/GroupDayBookList.vue"),
              meta: {
                requiresAuth: true,
                requiresDomain: true,
              }
            },
            {
              name: "schoolTimetableGroupRegisterAndLunch",
              path: 'lunch/',

              props: true,
              component: () => import("@/views/School/Timetable/Group/GroupRegisterAndLunch.vue"),
              meta: {
                requiresAuth: true,
                requiresDomain: true,
              }
            },
          ]

        },

        {
          path: 'staff/',
          name: "schoolTimetableAllStaff",
          component: () => import('@/views/School/Timetable/AllStaff.vue'),
          meta: {
            requiresAuth: true,
            requiresDomain: true,
            requiresStaff: true,
          }

        },
        {
          path: 'cover/:date+',
          name: "schoolTimetableCover",
          component: () => import('@/views/School/Timetable/Cover.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            requiresDomain: true,
            requiresStaff: true,
          }

        },


      ],
    },
    {
      path: '/school/tutor/:gid+',
      name: "schoolTutorGroup",
      component: () => import("@/views/School/Tutor.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }

    },

    {
      path: '/school/message',
      name: "schoolMessaging",
      component: () => import("@/views/School/MessageHome.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }

    },

    {
      path: '/school/assessments',
      name: "schoolAssessments",
      component: () => import("@/views/School/AssessmentManager.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }

    },

    {
      path: '/school/register/missing',
      name: "schoolRegisterMissing",
      component: () => import("@/views/School/RegisterMissing.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }

    },

    {
      path: '/school/reports/',
      name: "schoolReports",
      component: () => import("@/views/School/Report.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }

    },
    {
      path: '/school/links/',
      name: "schoolLinks",
      component: () => import("@/views/School/Links/View.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }

    },

    {
      path: '/system/',
      name: "systemManager",
      component: () => import("@/views/System/Manage.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
        requiresAccessLevel: 25,
      }

    },

    {
      path: '/school/links/manage/',
      name: "schoolLinksManager",
      component: () => import("@/views/School/Links/Manage.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
        requiresAccessLevel: 25,
      }

    },

    {
      path: '/school/groups/manage/',
      name: "schoolGroupsManager",
      component: () => import("@/views/School/ManageOptions.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
        requiresAccessLevel: 25,
      }

    },

    {
      path: '/school/files/manage/',
      name: "schoolFilesManager",
      component: () => import("@/views/School/FileManager.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
        requiresAccessLevel: 25,
      }

    },

    {
      path: '/school/student/search/',
      name: "pupilSearch",
      component: () => import("@/views/School/StudentSearch.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }
    },

    {
      path: '/school/student/:sid+/',
      name: "pupilInfo",
      component: () => import("@/views/School/StudentInfo.vue"),
      meta: {
        requiresAuth: true,
        requiresDomain: true,
        requiresStaff: true,
      }
    }


  ]
});

const mainMenu = document.getElementById('main-menu');
const burger = document.getElementById('example-burger');

router.beforeEach((to, from, next) => {
  const hideNav = to.matched.some((record) => record.meta.hidenav)
  const kiosk = to.matched.some((record) => record.meta.kiosk)
  const currentUser = store.getters.authenticatedUserDetails
  const currentUserEmailVerified = currentUser?.emailVerified ?? false

  const requiresDepartment = to.matched.some((record) => record.meta.requiresDepartment);
  const requiresAccessLevel = to.matched.some((record) => record.meta.requiresAccessLevel);
  const requiresStaff = requiresDepartment || requiresAccessLevel || (to.matched.some((record) => record.meta.requiresStaff));


  const allowedUnverified = to.matched.some((record) => record.meta.allowedUnverifiedEmail);
  const requiresDomain = requiresStaff || to.matched.some((record) => record.meta.requiresDomain);
  const requiresAuth = requiresDomain || requiresStaff || to.matched.some((record) => record.meta.requiresAuth);

  const requiresDomains = to.matched.some((record) => record.meta.requiresDomains);

  const allowedDomains = store.getters.domainsAllowed





  console.log("User:", currentUser)
  console.log({ allowedDomains })
  const dropDowns = document.querySelectorAll('div.navbar-dropdown');
  console.log([{ to, from, hideNav, kiosk, currentUser, requiresDepartment, requiresAccessLevel, requiresStaff, requiresDomain, requiresAuth, requiresDomains }, "abxdef"])






  dropDowns.forEach(function (el) {
    console.log(el)
    console.log(el.style)
    console.log({ parent: el.parentElement })

    el.parentElement.classList.remove("is-hoverable")

    el.parentElement.blur()

    setTimeout(() => {
      el.parentElement.classList.add("is-hoverable")
    }, 200)


  });



  if (mainMenu) {
    //console.log(mainMenu)
    mainMenu.classList.remove('is-active');
  }
  if (burger) {
    //console.log(burger)
    burger.classList.remove('is-active');
  }

  if (hideNav) {
    store.commit('hideNav')
  }
  else {
    store.commit('showNav')
  }


  if (requiresAuth) {

    if (!currentUser) {
      console.log("Needs auth and no user")
      next({ path: '/auth' });
      return;
    }

    if (!currentUserEmailVerified && !allowedUnverified) {
      console.log("Needs auth and no user")
      next({ path: '/verify' });
      return;
    }
  }


  if (requiresDomain) {

    if (!store.state.currentUserDataNew.retrieved) {

      store.commit("afterInterstitial", to.fullPath)

      next({ path: '/interstitial?a=1' });
      return




    }
    console.log(["in requires domain", store.state.currentDomain])
    if (!store.state.currentDomain) {



      let d = ls('currentDomain') ?? {}

      console.log(["xxxxxxx", d])

      if (!d || typeof (d) !== "string" || d == "" || !allowedDomains.includes(d)) {

        if (allowedDomains.length === 0) {
          next({ path: '/noassociated?a=1' });
          return;
        }

        if (allowedDomains.length !== 1) {

          console.log("Hmmm, no domain and need one")
          next({ path: '/school/chooser' });
          return;
        }

        d = allowedDomains[0]
      }

      console.log("Setting domain to:", d)

      store.commit("setCurrentDomain", d)

    }

  }

  if (requiresDomains) {
    if (allowedDomains.length === 0) {
      next({ path: '/noassociated?a=2' });
      return;
    }
  }

  if (requiresStaff) {
    if (!store.getters.isStaff) {
      next({ path: '/home' });
      return;
    }

  }


  if (requiresDepartment) {
    let intersection = to.matched[0].meta.requiresDepartment.filter(x => store.getters.currentUserDetails["DepartmentIds"].includes(x));
    if (!intersection || intersection.length == 0) {
      next({ path: '/home' });
      return;
    }
  }

  if (requiresAccessLevel) {

    if (!store.getters.isAccessLevel(to.matched[0].meta.requiresAccessLevel)) {
      next({ path: '/home' });
      return;
    }
  }




  if (kiosk) {
    next();
    return;
  }

  console.log("eheheheheheh")
  next();

});

export default router;
