import Vuex from 'vuex'
import Vue from 'vue'
import format from "date-fns/format"
import api from 'helpers/apicalls';

const ls = require('local-storage')

Vue.use(Vuex)

const store = new Vuex.Store({

  state: {
    showNavigation: true,
    organisation: {
      name: "The King's School"
    },
    realtimeClient: null,
    channel: null,
    inPortal: false,
    lastInteraction: null,
    timeOfDay: "Day",
    currentDate: format(new Date(), "cccc, do LLL yyyy"),
    currentDateObj: new Date(),
    currentTime: format(new Date(), "pp"),
    currentHM: format(new Date(), "HH:mm"),


    currentDomain: null,
    currentTimeTable: null,
    currentISODate: null,

    currentGroup: null,
    currentRegGroup: null,
    currentGroupUpdate: -1,

    afterInterstitial: undefined,


    lastSeenMessages: ls("lastSeen") ?? 0,
    lastReceivedMessage: 0,
    isStaff: false,
    isStudent: false,
    messages: [],
    currentUserObj: null,
    currentUserDataNew: { nextCheck: "0", getting: false, domains: ls("ssUserNew") },

    forms: [],
    streams: []



  },
  mutations: {


    afterInterstitial(state, nextPath) {

      state.afterInterstitial = nextPath
    },

    userDataNew(state, userData) {

      if (userData) {

        const x = Object.keys(userData).filter((key) => !key.startsWith('_'))

        userData = x.reduce((cur, key) => { return Object.assign(cur, { [key]: userData[key] }) }, {});

      }
      state.currentUserDataNew = {
        domains: userData,
        nextCheck: (new Date(new Date().getTime() + (30 * 60000))).toISOString(),
        getting: false,
        retrieved: true,
      }



      ls("ssUserNew", userData);
    },


    currentUser(state, user) {
      state.currentUserObj = user
      if (!user) {
        this.commit("resetAuthenticatedData")
      }
    },

    resetAuthenticatedData(state) {
      state.currentDomain = null
      state.currentTimeTable = null
      state.currentGroup = null
      state.currentRegGroup = null
      state.currentISODate = null
      state.lastSeenMessages = 0
      state.lastReceivedMessage = 0
      state.isStaff = false
      state.isStudent = false
      state.messages = []
      state.currentUserObj = null
      state.currentUserDataNew = { nextCheck: "0", getting: false, retrieved: false, domains: {} }

      state.forms = []
      state.streams = []

      ls("lastSeen", 0)
      ls("ssUserNew", null)

    },





    addMessage(state, payload) {
      state.messages.push(payload);
      if (payload.timestamp ?? 0 > state.lastReceivedMessage) {
        state.lastReceivedMessage = payload.timestamp;
      }
    },

    setCurrentDomain(state, domain) {

      console.log([state.currentUserDataNew, domain, "HHSHSHSHSHSHS"])
      state.isStaff = false;
      state.isStudent = false
      if (!state.currentUserDataNew?.domains?.[domain]) {
        state.currentDomain = null
        return false;
      }

      state.currentDomain = domain

      if (state.currentUserDataNew?.domains?.[domain]?.staff) {
        state.isStaff = (typeof (state.currentUserDataNew.domains[domain].staff) == "object")
      }

      if (state.currentUserDataNew?.domains?.[domain]?.student) {
        state.isStudent = (typeof (state.currentUserDataNew.domains[domain].student) == "object")
      }

      state.currentGroup = null
      state.currentRegGroup = null

      console.log(state.currentUserDataNew.domains[domain])
    },


    setFormsList(state, forms) {
      state.forms = forms
    },

    setWorkflowsList(state, forms) {
      state.streams = forms
    },

    setCurrentTimeTable(state, timetable) {
      //console.log(["here 3", timetable])
      state.currentTimeTable = timetable;
    },

    clearCurrentDomain(state) {
      state.isStaff = false;
      state.isStudent = false
      state.currentDomain = null;
    },

    setCurrentGroup(state, group) {
      state.currentGroup = group
      if (group === null) {
        state.currentRegGroup = null
      }
    },

    setCurrentRegGroup(state, group) {
      state.currentRegGroup = group
    },

    updateCurrentRegGroup(state, students) {

      let ok = true;
      let y = {}
      let instance = this
      Object.keys(students["data"]).forEach(element => {
        if (students["data"][element].GroupID != instance.gid) {
          ok = false
          return;
        }

        y[students["data"][element]["PupilID"]] = students["data"][element]
      });

      if (ok) {
        state.currentRegGroup.students = y
      }

    },



    showNav(state) {
      //console.log("showing nav....")
      state.showNavigation = true;
    },
    hideNav(state) {
      state.showNavigation = false;
    },
    interaction(state) {
      state.lastInteraction = Date.now();
    },
    portalStart(state) {
      state.inPortal = true;
      state.lastInteraction = Date.now();
      state.showNavigation = false;
    },
    updateTime(state) {
      state.currentDateObj = new Date()

      var h = state.currentDateObj.getHours(); // 0 - 23

      if (h > 11) {
        if (h > 16) {
          state.timeOfDay = "Evening"
        }
        else {
          state.timeOfDay = "Afternoon"
        }
      }
      else {
        state.timeOfDay = "Morning"
      }

      state.currentDate = format(state.currentDateObj, "cccc, do LLL yyyy")
      state.currentTime = format(state.currentDateObj, "pp")
      state.currentHM = format(state.currentDateObj, "HH:mm")
      state.currentISODate = state.currentDateObj.toISOString().substring(0, 10)


      // TODO - need to back off if failure

      if ((state.currentUserDataNew.nextCheck ?? "0") < state.currentDateObj.toISOString()) {
        this.commit("forceUpdateUserDetails");
      }
    },


    acknowledgeFlag: async function (state, params) {


      let { student, type, key, timestamp } = params

      console.log({ student, type, key, timestamp })



      if (!state.currentGroup.group.studentDetails[student].acked) {
        state.currentGroup.group.studentDetails[student].acked = {}
      }

      if (!state.currentGroup.group.studentDetails[student].acked[type]) {
        state.currentGroup.group.studentDetails[student].acked[type] = {}
      }

      state.currentGroup.group.studentDetails[student].acked[type][key] = timestamp
      state.currentGroupUpdate = -1 * state.currentGroupUpdate

      //console.log("asasasasas", [x, this.groups])
    },

    acknowledgeDayBooks: async function (state) {
      (state.currentGroup?.group?.students ?? []).forEach((v, i) => {
        state.currentGroup.group.students[i].unseenDaybooks = false

        Object.keys(state.currentGroup.group.students[i].daybook ?? {}).forEach(y => {
          state.currentGroup.group.students[i].daybook[y].forEach((z, j) => {
            state.currentGroup.group.students[i].daybook[y][j].seen = true
          })
        })
      })
      state.currentGroupUpdate = -1 * state.currentGroupUpdate
    },


    forceUpdateUserDetails(state) {

      if (state.currentUserDataNew.getting === false) {
        state.currentUserDataNew.getting = true;
        api.get("v1/me/", true, false).then(data => this.commit('userDataNew', data))
      }
    }


  },

  getters: {

    currentTimeTable: state => {

      return state.currentUserDataNew?.domains?.[state.currentDomain]?.rtt

    },

    newMessages: state => {

      return state.lastSeenMessages < state.lastReceivedMessage
    },

    isAuthenticatedUser: state => {
      return state.currentUserObj !== null
    },


    domainsAllowed: state => {
      return Object.keys(state.currentUserDataNew?.domains ?? {});
    },

    isStaff: state => {
      return state.isStaff
    },

    isStudent: state => {
      return state.isStudent
    },


    isAccessLevel: (state) => (level) => {
      if (!state.currentDomain) {
        return false;
      }
      if (!state.isStaff) {
        return false
      }

      if (!state.currentUserDataNew.domains[state.currentDomain]) {
        return false
      }

      return (state.currentUserDataNew.domains[state.currentDomain].staff?.AccessLevel >= level)
    },

    timetableDay: state => {

      console.log([state.currentTimeTable?.data?.[0]?.timetableDayName ?? '', ''])



      return state.currentTimeTable?.data?.[0]?.timetableDayName ?? ''

    },

    isTimetabled: state => {

      if (!state.currentTimeTable?.data[0]) {
        return false;
      }

      let date = new Date(state.currentTimeTable.data[0].date).toISOString().slice(0, 10);
      let today = new Date().toISOString().slice(0, 10);

      return (date === today)
    },


    currentDomainDetails: state => {

      if (!state.currentDomain) {
        //console.log("No domain")
        return {};
      }

      if (!state.currentUserDataNew.domains[state.currentDomain]) {
        //console.log("No domain in user data")
        return {};
      }

      return state.currentUserDataNew.domains[state.currentDomain]

    },

    authenticatedUserDetails: state => {
      return state.currentUserObj
    },

    canPutStudentOnReport: (state, getters) => {

      if (getters.isAccessLevel(25)) {
        return true
      }

      return ["d.hampton@thekingsschool.org.uk", "b.langmead@thekingsschool.org.uk", "a.howard@thekingsschool.org.uk"].includes(state.currentUserObj?.email ?? "_")



    },



    currentUserDetails: state => {
      if (!state.currentDomain) {
        console.log("No domain")
        return {};
      }

      if (!state.currentUserDataNew.domains[state.currentDomain]) {
        console.log("No domain in user data")
        return {};
      }

      if (state.currentUserDataNew.domains[state.currentDomain].staff) {
        return state.currentUserDataNew.domains[state.currentDomain].staff;
      }

      if (state.currentUserDataNew.domains[state.currentDomain].student) {
        return state.currentUserDataNew.domains[state.currentDomain].student;
      }

      // TODO - would return parent details

      return {}
    }



  },

  actions: {



    async updateTime() {

      setInterval(() => {
        this.commit("updateTime");
      },
        1000)
    },



  }
})

export default store
